<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
          <template slot="title">
            <strong>Data Pencairan Pokok</strong>
            <a-button-group class="ml-2">
              <a-button
                icon="plus-circle"
                class="text-primary"
                type=""
                @click="cairpokokmodalvisible = !cairpokokmodalvisible"
              >
                Tambah
              </a-button>
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Refresh Data</span>
                </template>
                <a-button @click="getAllData(true)">
                  <a-icon type="sync" :spin="loading" class="text-success" />
                </a-button>
              </a-tooltip>
              <!-- <a-tooltip placement="top">
            <template slot="title">
              <span>PDF Download</span>
            </template>
            <a-button @click="pdfMutasiPencairanPokok">
              <a-icon type="file-pdf" class="text-warning"/>
            </a-button>
          </a-tooltip> -->
              <!-- <a-tooltip placement="top">
            <template slot="title">
              <span>Setting Pengesahan</span>
            </template>
            <a-button @click="showModalPengesahan">
              <a-icon type="setting" class="text-secondary"/>
            </a-button>
          </a-tooltip> -->
              <!-- <a-dropdown>
              <a-button
                class="text-success"
                :loading="importBtnLoading"
                ><a-icon type="file-excel" class="text-success"/></a-button
              >
              <a-menu slot="overlay">
                <a-menu-item key="1" class="text-success" @click="exportExcel">
                  <a-icon type="file-excel" />Export Excel
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <input
              type="file"
              ref="excelFileUpload"
              style="display: none;"
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              @input="getSheetExcel"
            /> -->
              <!-- <a-tooltip placement="top">
            <template slot="title">
              <span>Export Data</span>
            </template>
            <a-button @click="exportExcel">
              <a-icon type="file-excel" class="text-success"/>
            </a-button>
          </a-tooltip> -->
            </a-button-group>
          </template>

          <!-- :scroll="{ x: 1000 }" -->
          <a-table
            :columns="columns"
            :dataSource="datatable"
            style="padding-top: -25px"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 10,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
            size="small"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            ></a-icon>
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                {{ text }}
              </template>
            </template>
            <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
            <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
            <span slot="tags" slot-scope="tags">
              <a-tag v-for="tag in tags" color="blue" :key="tag">{{
                tag
              }}</a-tag>
            </span>
            <span slot="action" slot-scope="text, record">
              <!-- <a-dropdown>
            <a-menu slot="overlay">
              <a-menu-item key="1" @click="registerMobileBanking(record.id)"> <a-icon type="user" />Register Mobile Banking</a-menu-item>
              <a-menu-item key="2" @click="phoneNumberMobileBankingModal(record.id, 'open')"> <a-icon type="number" />Change Phone Number</a-menu-item>
              <a-menu-item key="3" @click="resetMobileBanking(record.id)"> <a-icon type="reload" />Reset Phone Number</a-menu-item>
            </a-menu>
            <a class="text-primary">
              <a-icon type="menu"></a-icon>
            </a>
          </a-dropdown> -->
              <!-- <a-button style="margin-left: 8px"> Button <a-icon type="down" /> </a-button> -->
              <!-- <a-divider type="vertical" /> -->
              <!-- <a class="text-success" @click="cetakValidasi(record)" href="javascript:void(0)">
                <a-icon type="printer"></a-icon>
              </a> -->
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Delete Pencairan Pokok</span>
                </template>
                <a
                  href="javascript:void(0)"
                  class="text-danger"
                  @click="showDeleteConfirm(record)"
                  v-if="record.nominal !== 0"
                >
                  <a-icon type="delete"></a-icon>
                </a>
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Cetak Bilyet</span>
                </template>
                <a
                  href="javascript:void(0)"
                  class="text-warning"
                  @click="cetakBilyet(record.id)"
                >
                  <a-icon type="printer"></a-icon>
                </a>
              </a-tooltip>
              <!-- <a-divider type="vertical"
              v-if="record.nominal === 0"/>
              <a
                href="javascript:void(0)"
                class="text-primary"
                @click="sendToEdit(record)"
                v-if="record.nominal === 0"
              >
                <a-icon type="plus"></a-icon>
              </a> -->
              <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
            </span>
            <span slot="kodetransaksi" slot-scope="text, record">
              {{
                findRelationData(
                  record,
                  "masterkodeTransaksi",
                  "kode",
                  "kodetransaksi",
                  "keterangan"
                )
              }}
            </span>
            <!-- <span slot="printed" slot-scope="text">
              <a-tag :color="text === 0 ? 'secondary' : 'green'">{{ text === 0 ? 'Not Printed' : 'Printed' }}</a-tag>
            </span> -->
            <span slot="formatNominal" slot-scope="text">
              {{ formatCurrency(text) }}
            </span>
            <span slot="formatDate" slot-scope="text">
              {{ formatDate(text) }}
            </span>
            <span slot="created_at" slot-scope="text">
              {{
                formatDate(text, "YYYY-MM-DDThh:mm:ss", "DD-MM-YYYY hh:mm:ss")
              }}
            </span>
          </a-table>
        </a-card>
      </div>
    </div>
    <!-- <modalpengesahan ref="modalPengesahan"/> -->
    <a-modal title="Pencairan Pokok" v-model="cairpokokmodalvisible">
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="cairpokokmodalvisible = false">Cancel</a-button>
        <a-button class="btn btn-outline-primary" @click="showPencairanConfirm">Process</a-button>
      </template>
      <div class="row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <label>Tanggal</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
          <a-date-picker
            style="width: 130px"
            v-model="cairpokokmodal.formData.tgl"
            :allowClear="false"
            format="DD-MM-YYYY"
            @change="
              (dateMoment, dateString) =>
                changeDateInput(dateMoment, dateString, ['formData', 'tgl'])
            "
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label>Rekening</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input v-model="cairpokokmodal.formData.rekening" style="width: 60%;"></a-input>
          <a-button
            class="btn btn-outline-primary ml-1"
            @click="carinasabahvisible = !carinasabahvisible"
          >Find Rekening</a-button>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label>Nama</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input v-model="cairpokokmodal.maskInput.nama" style="width: 200px" :readOnly="true"></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label>Nominal</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input v-model="cairpokokmodal.maskInput.nominal" style="width: 200px" :readOnly="true"></a-input>
        </div>
      </div>
    </a-modal>

    <a-modal
      v-model="carinasabahvisible"
      :dialogStyle="{ top: '10px' }"
      :footer="null"
      :width="800"
    >
      <template slot="title">
        <strong>{{ carinasabahtitle }} </strong>
        <label style="margin: unset" v-if="selectedRekening !== ''">
          | Active Rekening: {{ selectedRekening }}</label
        >
      </template>
      <!-- <template slot="footer">
          <a-button class="btn btn-outline-dark">Cancel</a-button>
          <a-button class="btn btn-outline-primary">Process</a-button>
        </template> -->
      <div class="row">
        <a-input
          class="ml-3"
          placeholder="Ketik Nama"
          v-model="input.nama"
          style="width: 200px"
          @keydown.enter="findData"
        ></a-input>
        <!-- <a-input
          class="ml-2"
          placeholder="Ketik Alamat"
          v-model="input.alamat"
          style="width: 200px"
          @keydown.enter="findData"
        ></a-input>
        <a-select
          style="width: 70px"
          placeholder="Kode"
          class="ml-2"
          v-model="input.cabang"
        >
          <a-select-option value="01">01</a-select-option>
        </a-select> -->
        <a-button
          v-if="selectedRekening !== ''"
          class="btn btn-outline-danger btn-sm ml-2"
          @click="clearRekening"
          >Clear Selected Rekening</a-button
        >
        <a-divider type="horizontal" style="margin: 8px 8px" />
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <label
            v-if="
              table.tableatas.datatable.length !== null &&
              table.tableatas.datatable.length !== 0
            "
            class="text-primary"
            style="
              margin-left: 0.5rem;
              margin-top: 0.5rem;
              margin-bottom: unset !important;
            "
            >Jumlah Data: {{ table.tableatas.datatable.length }}</label
          >
          <a-table
            :scroll="{ y: 300 }"
            :columns="table.tableatas.column"
            :dataSource="table.tableatas.datatable"
            :pagination="false"
            size="small"
            :customRow="customRow"
          >
            <template slot="actionButton" slot-scope="index, record">
              <a-button
                class="btn btn-outline-primary"
                @click="selectModalTable1(record)"
                >Pilih</a-button
              >
            </template>
          </a-table>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
          <label
            v-if="
              table.tablebawah.datatable.length !== null &&
              table.tablebawah.datatable.length !== 0
            "
            class="text-primary"
            style="
              margin-left: 0.5rem;
              margin-top: 0.5rem;
              margin-bottom: unset !important;
            "
            >Jumlah Data: {{ table.tablebawah.datatable.length }}</label
          >
          <a-table
            :scroll="{ y: 200 }"
            :columns="table.tablebawah.column"
            :dataSource="table.tablebawah.datatable"
            :pagination="false"
            size="small"
            :customRow="customRowB"
          >
            <span slot="saldo" slot-scope="text">
              {{ formatCurrency(text) }}
            </span>
            <template slot="actionButton" slot-scope="index, record">
              <a-button
                class="btn btn-outline-primary"
                @click="selectModalTable2(record)"
                >Pilih</a-button
              >
            </template>
          </a-table>
        </div> -->
      </div>
    </a-modal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'
// import modalpengesahan from '../settings/modalpengesahan.vue'
/* End Script Import Script */
export default {
  components: {
    // modalpengesahan,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  data() {
    this.lastFetchId = 0
    this.rekeningSearch = debounce(this.rekeningSearch, 800)
    return {
      loading: false,
      theme: this.$store.state.settings.theme,
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'Tablesimpanan',
      visible: false,
      columns: [
        {
          title: 'Action',
          scopedSlots: { customRender: 'action' },
          width: 70,
          fixed: 'left',
        },
        {
          title: 'Tgl',
          width: 100,
          dataIndex: 'tgl',
          scopedSlots: { customRender: 'formatDate' },
          // width: 100,
        },
        {
          title: 'Faktur',
          dataIndex: 'faktur',
          width: 200,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.faktur
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Rekening',
          dataIndex: 'rekening',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.rekening
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Nama',
          width: 250,
          dataIndex: 'nama',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.nama.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          // width: 100,
        },
        {
          title: 'Debet',
          width: 100,
          dataIndex: 'debet',
          scopedSlots: { customRender: 'formatNominal' },
          // width: 100,
        },
        {
          title: 'Created At',
          dataIndex: 'created_at',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            customRender: 'created_at',
          },
        },
        {
          title: 'Username',
          width: 100,
          dataIndex: 'username',
          scopedSlots: { customRender: 'username' },
          // width: 100,
        },
        // {
        //   title: 'printed',
        //   width: 80,
        //   dataIndex: 'printed',
        //   scopedSlots: { customRender: 'printed' },
        //   // width: 100,
        // },
      ],
      masterkodeTransaksi: [],
      backupmasterkodeTransaksi: [],
      datatable: [],
      componentKey: 0,

      // Form
      cairpokokmodalvisible: false,
      cairpokokmodal: {
        formData: {
          rekening: '',
          tgl: moment(),
        },
        maskInput: {
          nama: '',
          nominal: 0,
        },
      },

      // Modal cari nasabah property
      carinasabahvisible: false,
      carinasabahtitle: 'Cari Nasabah',
      selectedRekening: '',
      input: {
        nama: '',
        alamat: '',
        cabang: '',
      },
      table: {
        tableatas: {
          column: [
            {
              title: 'Rekening',
              rowKey: 'rekening',
              dataIndex: 'rekening',
              scopedSlots: { customRender: 'rekening' },
              width: 100,
            },
            {
              title: 'Nama',
              dataIndex: 'nama',
              scopedSlots: { customRender: 'nama' },
              width: 150,
            },
            {
              title: 'Bilyet',
              dataIndex: 'nomor_bilyet',
              scopedSlots: { customRender: 'nomor_bilyet' },
              width: 100,
            },
            // {
            //   title: 'KTP',
            //   dataIndex: 'ktp',
            //   scopedSlots: { customRender: 'ktp' },
            //   width: 100,
            // },
            // {
            //   title: 'Alamat',
            //   dataIndex: 'alamat',
            //   scopedSlots: { customRender: 'alamat' },
            //   // width: 100,
            // },
            {
              title: '#',
              scopedSlots: { customRender: 'actionButton' },
              width: 70,
            },
          ],
          datatable: [],
        },
        tablebawah: {
          column: [
            {
              title: 'Jenis',
              dataIndex: 'jenis',
              scopedSlots: { customRender: 'jenis' },
              // width: 100,
            },
            {
              title: 'Rekening',
              dataIndex: 'rekening',
              scopedSlots: { customRender: 'rekening' },
              // width: 100,
            },
            {
              title: 'Saldo',
              dataIndex: 'saldo',
              scopedSlots: { customRender: 'saldo' },
              // width: 100,
            },
            {
              title: '#',
              scopedSlots: { customRender: 'actionButton' },
              // width: 70,
            },
          ],
          datatable: [],
        },
      },
    }
  },
  /* End Data variable vue */
  mounted: function () {
    this.gridOptionsModalExcel = {}
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    localStorage.removeItem(
      'koperasisyariaheditsimpananberjangkapencairanpokokdata',
    )
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    this.getAllData()
    // this.getAgamaF()
  },
  methods: {
    moment,
    // Modal Cari Nasabah Function
    clearRekening() {
      this.selectedRekening = ''
    },
    async findData() {
      // console.log('this.formData.rekening', this.formData.rekening)
      var imbuhan = ''
      imbuhan += this.input.nama !== '' ? '?q=' + this.input.nama : ''
      // imbuhan +=
      //   this.input.alamat !== ''
      //     ? this.input.nama !== ''
      //       ? '&Alamat=' + this.input.alamat
      //       : '?Alamat=' + this.input.alamat
      //     : ''
      // imbuhan += this.input.cabang !== '' ? '&cabang=' + this.input.cabang : ''
      var res = await lou.customUrlGet2(
        'transaksi/simpananberjangka/pencairanpokok/cari' + imbuhan,
        false,
      )
      if (res) {
        if (res.data.length === 0) {
          lou.shownotif('Info', 'Data tidak ditemukan!', 'warning')
          this.table.tableatas.datatable = []
        } else {
          lou.shownotif(
            'Info',
            'Ditemukan ' + res.data.length + ' data yang cocok!',
            'success',
          )
          this.table.tableatas.datatable = res.data
        }
        // for (let i = 0; i < 20; i++) {
        //   const element = res.data[0]
        //   this.table.tableatas.datatable.push(element)
        // }
      }
    },
    async nasabahDetail(record) {
      if (!this.loading) {
        this.loading = true
        var res = await lou.customUrlGet2(
          'transaksi/simpanan/cari_rekening?kode=' + record.kode,
          false,
        )
        if (res) {
          if (res.data.length === 0) {
            lou.shownotif(
              'Info',
              'Register tidak memiliki rekening simpanan!',
              'warning',
            )
            this.table.tablebawah.datatable = []
          } else {
            lou.shownotif(
              'Info',
              'Ditemukan ' + res.data.length + ' data yang cocok!',
              'success',
            )
            this.table.tablebawah.datatable = res.data
          }
          this.listKode = this.$g.clone(res.data)
          this.backuplistKode = this.$g.clone(res.data)
          this.loading = false
        }
        this.loading = false
      }
    },
    selectModalTable1(record) {
      this.selectedKodeRegister = record.kode
      this.formData.kode = record.kode
      this.maskInput.nama = record.nama
      this.nasabahDetail(record)
      this.carinasabahvisible = false
    },
    selectModalTable2(record) {
      // console.log('record', record)
      // this.selectedKodeRegister = record.kode
      // this.formData.kode = record.kode
      this.selectedRekening = record.rekening
      this.formData.rekening = record.rekening
      this.carinasabahvisible = false
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            this.selectedKodeRegister = record.rekening
            this.cairpokokmodal.formData.rekening = record.rekening
            this.cairpokokmodal.maskInput.nama = record.nama
            this.cairpokokmodal.maskInput.nominal = this.formatCurrency(record.nominal)
            this.carinasabahvisible = !this.carinasabahvisible
            // this.formData.kode = record.kode
            // this.maskInput.nama = record.nama
            // this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    customRowB(record, index) {
      return {
        on: {
          click: (event) => {
            // this.selectedKodeRegister = record.kode
            // this.formData.kode = record.kode
            // console.log('record', record)
            this.selectedRekening = record.rekening
            // console.log('index', index)
            // console.log('event', event)
            // console.log('this.selectedRekening', this.selectedRekening)
            this.formData.rekening = record.rekening
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    // End of Modal Cari Nasabah Function

    fetchUser(value) {
      // console.log('fetching user', value)
      // var hasil = this.backupmasterKota.filter(x => x.city_name.toLowerCase().includes(nval))
      // console.log('hasil', hasil)
      if (value !== '') {
        var nval = value.toLowerCase()
        this.lastFetchId += 1
        const fetchId = this.lastFetchId
        this.masterKota = []
        this.fetching = true
        var res = []
        res = this.backupmasterKota.filter((x) =>
          x.city_name.toLowerCase().includes(nval),
        )
        if (res.length !== 0) {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return false
          }
          this.masterKota = res.data
          this.componentKey += 1
          this.fetching = false
        } else {
          this.fetching = null
        }
      }
    },
    async rekeningSearch(value) {
      if (value !== '' && value.length >= 3) {
        this.fetching = true
        // this.formData.kode = this.formData.cabang + this.formData.rekode
        var response = await lou.customUrlGet2(
          'transaksi/simpanan/cari_nama?q=' + value,
        )
        if (response) {
          this.listRekening = this.$g.clone(response.data)
          this.backuplistRekening = this.$g.clone(response.data)
          this.fetching = false
          this.componentKey += 1
          // console.log('this.backupmasterKota', this.backupmasterKota)
        } else {
          this.fetching = false
        }
      }
    },
    async cetakBilyet(id) {
      var res = await lou.ngetDocumentPdf(
        'transaksi/simpananberjangka/pencairanpokok/validasi/' + id,
        false,
        false,
      )
      if (res) {
        // console.log("Success", response);
        const blob = new Blob([res], {
          type: 'application/pdf',
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.target = '_blank'
        // link.download = filename
        link.click()
        // const objectUrl = URL.createObjectURL(blob)
        // console.log('objectUrl', objectUrl)
        // this.amodal.ArrayBuffer = objectUrl
        URL.revokeObjectURL(blob)
        // setTimeout(() => {
        // this.componentKey += 1
        // this.amodalvisible = true
        // }, 500)
      }
    },

    // Modal func
    async showModalPengesahan() {
      var res = await lou.customUrlGet2(
        'general/master/2/pengesahan?kode=Mutasi Pencairan Pokok',
      )
      this.$refs.modalPengesahan.$data.input = res.data
      // console.log('this.$refs.modalPengesahan.$data.input', this.$refs.modalPengesahan.$data.input)
      this.$refs.modalPengesahan.showModal()
    },
    // End of Modal func

    /* this is direct download for pdf without preview it first */
    async pdfMutasiPencairanPokok() {
      var res = await lou.ngetDocumentPdf(
        'transaksi/simpananberjangka/pencairanpokok?preview=pdf',
        false,
        false,
      )
      if (res) {
        // console.log("Success", response);
        const blob = new Blob([res], {
          type: 'application/pdf',
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.target = '_blank'
        // link.download = filename

        // link.download = ''
        link.click()
        // const objectUrl = URL.createObjectURL(blob)
        // console.log('objectUrl', objectUrl)
        // this.amodal.ArrayBuffer = objectUrl
        URL.revokeObjectURL(blob)
        setTimeout(() => {
          this.componentKey += 1
          // this.amodalvisible = true
        }, 500)
      }
    },
    async cetakValidasi(record) {
      var res = await lou.ngetDocumentPdf(
        'transaksi/simpananberjangka/pencairanpokok/cetak/' + record.id,
        false,
        false,
      )
      if (res) {
        // console.log("Success", response);
        const blob = new Blob([res], {
          type: 'application/pdf',
        })
        var iframe = this._printIframe
        var url = URL.createObjectURL(blob)
        if (!this._printIframe) {
          iframe = this._printIframe = document.createElement('iframe')
          document.body.appendChild(iframe)

          iframe.style.display = 'none'
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus()
              iframe.contentWindow.print()
            }, 1)
          }
        }

        iframe.src = url
        // const link = document.createElement('a')
        // link.href = URL.createObjectURL(blob)
        // // link.target = '_blank'
        // // link.download = filename

        // link.download = ''
        // link.click()
        // // const objectUrl = URL.createObjectURL(blob)
        // // console.log('objectUrl', objectUrl)
        // // this.amodal.ArrayBuffer = objectUrl
        // URL.revokeObjectURL(blob)
        setTimeout(() => {
          this.componentKey += 1
          // this.amodalvisible = true
        }, 500)
      }
    },
    connector() {
      // console.log('keanggotaan', keanggotaan)
      localStorage.setItem(
        'koperasisyariaheditsimpananberjangkapencairanpokokdata',
        JSON.stringify({ id: '' }),
      )
      this.$router.push(
        '/koperasisyariah/simpananberjangka/pencairanpokok/form',
      )
    },
    rangedofunction(date, dateString) {
      this.tgl1 = moment(dateString[0], 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.tgl2 = moment(dateString[1], 'DD-MM-YYYY').format('YYYY-MM-DD')
      // console.log('date', date)
      // console.log('dateString', dateString)
      // var res = this.
      // this.getAllData()
    },
    async getAllData(onclick = false) {
      this.loading = true
      if (!onclick) {
        var kodetransaksi = await lou.customUrlGet2(
          'transaksi/simpananberjangka/pencairanpokok/kode_transaksi',
        )
        if (kodetransaksi) {
          this.masterkodeTransaksi = this.$g.clone(kodetransaksi.data)
          this.backupmasterkodeTransaksi = this.$g.clone(kodetransaksi.data)
        }
      }
      var response = await lou.customUrlGet2(
        'transaksi/simpananberjangka/pencairanpokok',
      )
      if (response) {
        this.datatable = response.data
        this.loading = false
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      } else {
        this.loading = false
      }
      // console.log('this.datatable', this.datatable)
    },
    sendToEdit(edata) {
      // var ndata = {}
      // for (let i = 0; i < this.columns.length; i++) {
      //   const element = this.columns[i].dataIndex
      //   ndata[element.toString().toLowerCase()] = edata[element]
      // }
      // console.log('edata', edata)
      localStorage.setItem(
        'koperasisyariaheditsimpananberjangkapencairanpokokdata',
        JSON.stringify(edata),
      )
      this.$router.push(
        '/koperasisyariah/simpananberjangka/pencairanpokok/form',
      )
    },
    formatDate(value, formatFrom = 'YYYY-MM-DD', formatTo = 'DD-MM-YYYY') {
      return moment(value, formatFrom).format(formatTo)
    },
    async showPencairanConfirm() {
      var fd = {
        rekening: this.cairpokokmodal.formData.rekening,
        tgl: this.cairpokokmodal.formData.tgl,
      }
      // Modal.confirm({
      //   title: 'Pencairan?',
      //   content: 'Aksi tidak dapat dibatalkan setelah terconfirm!',
      //   okText: 'Yes',
      //   okType: 'primary',
      //   cancelText: 'No',
      //   onOk: async () => {
      var response = await lou.customUrlPost2(
        'transaksi/simpananberjangka/pencairanpokok',
        fd,
      )
      // this.datatable = response.data
      if (response) {
        this.cairpokokmodalvisible = false
        this.cairpokokmodal = {
          formData: {
            rekening: '',
            tgl: moment(),
          },
        }
        this.getAllData()
      } else {
      }
      //   },
      //   onCancel: () => {
      //     // console.log('Cancel')
      //   },
      // })
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Pembatalan Pencairan?',
        content: 'Aksi tidak dapat dibatalkan setelah terconfirm!',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2(
            'transaksi/simpananberjangka/pencairanpokok/' + deldata.id,
          )
          // this.datatable = response.data
          if (response) {
            this.getAllData()
          } else {
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    findRelationData(data, mastername, key, datakey, label) {
      // console.log('mastername', mastername)
      var target = this[mastername].findIndex((x) => x[key] === data[datakey])
      // console.log('data', data)
      // console.log('mastername', mastername)
      // console.log('key', key)
      // console.log('target', target)
      // console.log('this.allMaster[mastername][target]', this.allMaster[mastername][target])
      // console.log('this[mastername][target]', this[mastername][target])
      return target === -1
        ? 'Data tidak ditemukan'
        : this[mastername][target][label]
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style>
.ant-card-body {
  padding: 24px !important;
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
